import React from 'react';
import Assets from "../../constants/image"
import { Button } from 'react-bootstrap';
import { EditBlueIcon } from '../ThemeSvgs';

const MyProfileDetail = (props) => {
    return (
        <div className="myProfile">
            <div className="myProfile__Header">
                <h3 className="heading-small">My Profile</h3>
                <Button className="outlineBtn" onClick={() => { props?.changeProfile(false) }}><EditBlueIcon /> Edit</Button>
            </div>
            <div className="myProfile__Body">
                <div className="myProfile__img">
                    <img src={Assets.userImage} alt="my Profile" />
                </div>
                <div className="myProfile__name">
                    <p className='m-0'>Alex Joe</p>
                </div>
            </div>
            <div className="myProfile__details">
                <div class="myProfile__details__box"><span>Role:</span><p>Store Manager</p></div>
                <div class="myProfile__details__box"><span>Email:</span><a href="mailto:info@example.com">alex.doe@gmail.com</a></div>
            </div>
        </div>
    )
}

export default MyProfileDetail