import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import TurnoverTable from '../../../../components/History/TurnoverTable/TurnoverTable';

const breadCrumb = [
    {
        name: "History",
        link: "/history/sales",
    },
    {
        name: "Turnover Report",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 2,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 3,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 4,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 5,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 6,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 7,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 8,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 9,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 10,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    },
    {
        id: 11,
        name: "Jane Cooper",
        turnoverReport: "2 Years"
    }
]

const years = [
    { label: 2022, value: 2022 },
    { label: 2021, value: 2021 },
    { label: 2020, value: 2020 },
    { label: 2019, value: 2019 },
    { label: 2018, value: 2018 },
    { label: 2017, value: 2017 },
    { label: 2016, value: 2016 },
    { label: 2015, value: 2015 },
    { label: 2014, value: 2014 },
    { label: 2013, value: 2013 }
  ]

const TurnOverReport = () => {
    return (
        <>
            <FilterBar
                 pageName={"Turnover Report"}
                firstDropdown={years}
                secondDropdown={false}
                defaultValueFirst={years[0]}
                btnText={"Sync"}
                breadCrumb={breadCrumb}
            />
            <div className="pageContainer">
                <TurnoverTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default TurnOverReport