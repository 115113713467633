export const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]


export const timeSpan = [
    { value: '1', label: '8-11' },
    { value: '2', label: '14-16' },
    { value: '3', label: '18-20' }
]

export const defaultData = [
    {
        id: 1,
        weeklyText: "Dining Room",
        description: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate',
        comments: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate",
        date: "24/06/22",
        commentby: "Jane Cooper"
    },
    {
        id: 2,
        weeklyText: "Ice cream Machines",
        description: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate',
        comments: "",
        date: "24/06/22",
        commentby: "Jane Cooper"
    },
    {
        id: 3,
        weeklyText: "Boil Outs",
        description: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate',
        comments: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate",
        date: "24/06/22",
        commentby: "Jane Cooper"
    },
    {
        id: 4,
        weeklyText: "Walk Ins",
        description: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate',
        comments: "",
        date: "24/06/22",
        commentby: "Jane Cooper"
    },
    {
        id: 5,
        weeklyText: "Kitchen",
        description: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate',
        comments: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate",
        date: "24/06/22",
        commentby: "Jane Cooper"
    }

]

export const breadCrumb = [
    {
        name: "Daily Activities",
        link: "/daily-activities",
    },
    {
        name: "Weekly Cleaning",
        link: "",
    }
];

export const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]


