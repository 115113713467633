import React, { useContext, useState } from "react";
import {
    CrossSquared,
    DashboardIcon,
    DriveTimeIcon,
    EmployeesIcon,
    GoalsIcon,
    HistoryIcon, LogoSvg, LogoutIcon,
    ProductMixIcon,
    ReportsIcon, RoundPlusIcon, RumbucksIcon, SetupIcon, StoreLinksIcon
} from "./ThemeSvgs";
import { NavLink } from "react-router-dom";
import { LogoutPopupContext } from "../App"

const SideBar = (props) => {
    const [handleLogout, setHandleLogout] = useContext(LogoutPopupContext);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    }
    const SidebarList = [{
        pageLink: "/dashboard",
        pageIcon: <DashboardIcon />,
        pageName: "Dashboard",
    }, {
        pageLink: "/drive-times",
        pageIcon: <DriveTimeIcon />,
        pageName: "Drive Times",
    }, {
        pageLink: "/product-mix",
        pageIcon: <ProductMixIcon />,
        pageName: "Product Mix",
    },
    {
        pageLink: "/daily-activities",
        pageIcon: <GoalsIcon />,
        pageName: "Daily Activities",
        hasSubMenu: true, //Indicated that the element has the submenu
        subMenu: [
            {
                subMenuLink: "/daily-activities/pride",
                SubmenuName: "Pride"
            },
            {
                subMenuLink: "/daily-activities/figure-8",
                SubmenuName: "Figure-8"
            },
            {
                subMenuLink: "/daily-activities/weekly-cleaning",
                SubmenuName: "Weekly Cleaning"
            },
            {
                subMenuLink: "/daily-activities/temperatures",
                SubmenuName: "Temperatures"
            },
        ]
    },

    {
        pageLink: "/rumbucks",
        pageIcon: <RumbucksIcon />,
        pageName: "RUMBUCKS",
    },

    {
        pageLink: "/employees",
        pageIcon: <EmployeesIcon />,
        pageName: "Employees",
        hasSubMenu: "true",
        subMenu: [{
            subMenuLink: "/employees/anniversary",
            SubmenuName: "Anniversary"
        },
        {
            subMenuLink: "/employees/birthday",
            SubmenuName: "Birthday"
        },
        {
            subMenuLink: "/employees/tenure",
            SubmenuName: "Tenure"
        },
        {
            subMenuLink: "/employees/termination",
            SubmenuName: "Termination"
        },
        {
            subMenuLink: "/employees/turnover",
            SubmenuName: "Turnover"
        },
        {
            subMenuLink: "/employees/training",
            SubmenuName: "Training"
        }]
    }, {
        pageLink: "/reports/mix-and-chocolate-mix",
        pageIcon: <ReportsIcon />,
        pageName: "Reports",
        hasSubMenu: true, //Indicated that the element has the submenu
        subMenu: [{
            subMenuLink: "/reports/mix-and-chocolate-mix",
            SubmenuName: "Mix and Chocolate Mix"
        },
        {
            subMenuLink: "/reports/KWH",
            SubmenuName: "KWH"
        }]
    }, {
        pageLink: "/history/sales",
        pageIcon: <HistoryIcon />,
        pageName: "History",
        hasSubMenu: true,  //Indicated that the element has the submenu
        subMenu: [{
            subMenuLink: "/history/sales",
            SubmenuName: "Sales"
        },
        {
            subMenuLink: "/history/turnover-report",
            SubmenuName: "Turnover Report"
        },
        {
            subMenuLink: "/history/tenure-report",
            SubmenuName: "Tenure Report"
        },
        {
            subMenuLink: "/history/termination",
            SubmenuName: "Termination"
        },
        {
            subMenuLink: "/history/time-report",
            SubmenuName: "Time Report"
        }]
    }]
    
    ;
    return (
        <>
            <div className={props?.menuState ? `${"dashboardSidebar"} ${"active"}` : `${"dashboardSidebar"} `} >
                <NavLink to="/dashboard" className={"logo-dashboard"}>
                    <LogoSvg />
                </NavLink>
                <div className="d-sm-none crossMenu" onClick={() => { props?.setMenuState(false) }}>
                    <CrossSquared />
                </div>
                <ul className="sideBarList">
                    {
                        SidebarList?.map((list, index) => {
                            return (
                                <li key={index} className={`${list.hasSubMenu ? "hasChilList" : ""}`}>
                                    <NavLink className={`link-item nav-link ${list.hasSubMenu ? "has-children" : ""}`} to={list.pageLink}>
                                        <div className={"nav-bg"}>
                                            <span>
                                                {list.pageIcon}
                                            </span>
                                            <span className="ps-3" onClick={() => { props?.setMenuState(false) }}>{list.pageName}</span>


                                            {list?.hasSubMenu ? <ul className={index === activeIndex ? "nav-child-list active" : "nav-child-list"}>
                                                {
                                                    list?.hasSubMenu && list.subMenu.map((subMenuItem, index2) => {
                                                        return (
                                                            <li key={index2} className={"nav-child-item"} >
                                                                <NavLink className={"nav-link"} onClick={() => { props?.setMenuState(false) }} to={subMenuItem.subMenuLink}>
                                                                    {subMenuItem.SubmenuName}
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul> : null}
                                        </div>
                                    </NavLink>
                                    <div className="plusIcon d-sm-none" onClick={() => handleClick(index)}>
                                        <RoundPlusIcon />
                                    </div>
                                </li>
                            )
                        })
                    }
                    <li className="mt-3">
                        <div className={"link-item"} onClick={() => { setHandleLogout(true) }}>
                            <span>
                                <LogoutIcon />
                            </span>
                            <span className="ps-3">Logout</span>
                        </div>
                    </li>
                </ul>

            </div >

        </>
    )
}

export default SideBar