import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Table } from 'rsuite';
import Assets from '../../../constants/image';
import { Button, Pagination } from "react-bootstrap";
import { ArrowLeftBold, ArrowRight, DoubleArrowBold, EditIcon } from "../../ThemeSvgs";
import { FixedColumnContext, TableHeightContext } from "../../../App";

const TurnoverTable = (props) => {

    const [data, setData] = useState(props?.defaultData);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const { Column, HeaderCell, Cell } = Table; // Importing components from Rsuite Table
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);

    //Function to sort the data
    const getData = () => {
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    /** For Edit the Row */
    const handleEditChange = (id, key, value) => {
        const nextData = Object.assign([], data);
        nextData.find(item => item.id === id)[key] = value;
        setData(nextData);
    };
    const handleEditState = id => {
        const nextData = Object.assign([], data);
        console.log(nextData, "nextData");
        const activeItem = nextData.find(item => item.id === id);
        console.log(activeItem, "activeItem");
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };

    //** Editable Cell Functions */
    const timeRegExp = /^.+$/;

    const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
        const editing = rowData.status === 'EDIT';
        const [error, setError] = useState(false);
        const [inputValue, setInputValue] = useState(rowData[dataKey]);
        return (
            <Cell {...props} className={editing ? 'table-content-editing' : ''}>
                {editing ? (
                    <input
                        className={`rs-input ${error ? 'error' : ''}`}
                        value={inputValue}
                        onChange={event => setInputValue(event.target.value)}
                        onClick={() => {
                            if (inputValue === 'N/A') {
                                setInputValue('');
                            }
                        }}
                        onBlur={() => {
                            if (timeRegExp.test(inputValue)) {
                                setError(false);
                                onChange && onChange(rowData.id, dataKey, inputValue);
                            } else {
                                setError(true);
                                alert("Invalid Input. Please enter a valid value");
                            }
                        }}
                    />
                ) : (
                    <span className="table-content-edit-span">{rowData[dataKey]}</span>
                )}
            </Cell>
        );
    };
    const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
        const [error, setError] = useState(false);
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                <div className="editBtn">
                    <Button
                        appearance="link"
                        onClick={() => {
                            if (rowData.status === 'EDIT') {
                                if (timeRegExp.test(rowData[dataKey])) {
                                    onClick(rowData.id);
                                    setError(false);
                                } else {
                                    setError(true);
                                    alert("Invalid Input. Please enter a valid value");
                                }
                            } else {
                                onClick(rowData.id);
                            }
                        }}
                    >
                        {rowData.status === "EDIT" ? 'Save' : <EditIcon />}
                    </Button>
                </div>
            </Cell>
        );
    };



    return (
        <>
            <Table
                data={getData()}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<div class="text-center">
                                    <div class="nodatafolder mb-2">
                                        <img src="${Assets.noDataFolder}" alt="No data found" />
                                    </div>
                                    <p>No Data Found</p>
                                    </div>`
                            }}
                        />
                    )
                }}
                height={tableHeight}
                headerHeight={72}
                hover={false}
                loading={loading}
                cellBordered={false}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            >
                <Column width={150} align="left" fixed sortable>
                    <HeaderCell>Employee ID</HeaderCell>
                    <Cell dataKey="id" />
                </Column>

                <Column width={150} fixed sortable>
                    <HeaderCell>Name</HeaderCell>
                    <EditableCell dataKey="name" onChange={handleEditChange} />
                </Column>

                <Column width={150} fixed sortable>
                    <HeaderCell>Turnover Report</HeaderCell>
                    <EditableCell dataKey="turnoverReport" onChange={handleEditChange} />
                </Column>
                <Column width={120} align="center" fixed="right">
                    <HeaderCell className="text-center">Action</HeaderCell>
                    <ActionCell dataKey="id" onClick={handleEditState} />
                </Column>
            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <ArrowLeftBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow" >
                        <DoubleArrowBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default TurnoverTable