import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import AddTrainingPopup from '../../../../components/employees/Training/AddTrainingPopup';
import EditTrainingPopup from '../../../../components/employees/Training/EditTrainingPopup';
import TrainingTable from '../../../../components/employees/Training/TrainingTable';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Training",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 2,
        storename: 'Burnet',
        employees: 'Adamson Byron',
        trainingProgram: 'Production',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 3,
        storename: 'Manor',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 4,
        storename: 'Lockhart',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 5,
        storename: 'Stassney',
        employees: 'Adamson Byron',
        trainingProgram: 'Production',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 6,
        storename: 'Georgetown',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 7,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 8,
        storename: 'Georgetown',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 9,
        storename: 'Georgetown',
        employees: 'Adamson Byron',
        trainingProgram: 'Production',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 10,
        storename: 'Cameron',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 11,
        storename: 'RoundRock',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    },
    {
        id: 12,
        storename: 'AndersonMill',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/employees/training/course",
        completionDate: "23.45%",
        startdate:"26/02/22"


    }

]

const Training = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> training program?"} />}
            {editPopup === true && <EditTrainingPopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddTrainingPopup setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Training"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={"Add Training"}
                breadCrumb={breadCrumb}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                <TrainingTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default Training