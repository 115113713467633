import React, { useState } from "react";
import DriveTimesTable from "../../../components/DriveTimes/DriveTimesTable";
import FilterBar from "../../../components/FilterBar";

const monthProps = [
  { value: 'january', label: 'January' },
  { value: 'february', label: 'February' },
  { value: 'march', label: 'March' },
  { value: 'april', label: 'April' },
  { value: 'may', label: 'May' },
  { value: 'june', label: 'June' },
  { value: 'july', label: 'July' },
  { value: 'august', label: 'August' },
  { value: 'september', label: 'September' },
  { value: 'october', label: 'October' },
  { value: 'november', label: 'November' },
  { value: 'december', label: 'December' }
]



const DriveTimes = () => {

  return (
    <>
      <FilterBar
        pageName={"Drive times"}
        firstDropdown={false}
        secondDropdown={monthProps}
        defaultValueSecond={monthProps[8]}
        btnText={"Sync"} breadCrumb={false}
      />
      <div className="pageContainer">
        <DriveTimesTable />
      </div>
    </>
  )
}

export default DriveTimes