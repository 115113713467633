import React, { useState, useEffect } from 'react';
import { Table } from 'rsuite';
import { Button, Pagination } from "react-bootstrap";
import Assets from "../../../constants/image";
import { ArrowLeftBold, ArrowRight, DoubleArrowBold, EditIcon } from "../../ThemeSvgs";
import { Link } from 'react-router-dom';

const WeeklyCleaningTable = (props) => {
    const { Column, HeaderCell, Cell } = Table; // Importing components from Rsuite Table
    const [data, setData] = useState(props?.defaultData); //Set the default data to Data state
    const [loading, setLoading] = useState(false); //For Loading
    const [fixedState, setFixedState] = useState(true);  // Indicates about the fixed column
    const [sortColumn, setSortColumn] = useState(); //For Sorting Column
    const [sortType, setSortType] = useState(); //For Sorting column by type
    const [limit, setLimit] = useState(10); //For Pagination
    const [page, setPage] = useState(1);//For Pagination
    const [rowData, setRowData] = useState(null);
    const [tableHeight, setTableHeight] = useState()

    /** For Pagination */
    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };
    /** For Sorting */
    const getData = () => {
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const openDetailFunc = () => {
        props?.setshowPopup(true)
    }

    const openCommentDetailFunc = () => {
        props?.showCommentPopup(true)
    }
    // Description Cell 
    const DescriptionCell = ({ rowData, dataKey, onClick, ...props }) => {
        const charLimit = 92;
        let originalString = rowData.description;
        const shortenedString = originalString.slice(0, charLimit);
        let displayString = shortenedString;
        if (originalString.length > charLimit) {
            displayString += "...";
        }
        return (
            <Cell {...props}>
                <p>{displayString} {originalString.length > charLimit ? <button className="readMoreBtn" onClick={openDetailFunc}>Read More</button> : null}</p>
            </Cell>
        );
    };

    // Comments Cell 
    const CommentsCell = ({ rowData, dataKey, onClick, ...props }) => {
        const charLimit = 92;
        let originalString = rowData.comments;
        const shortenedString = originalString.slice(0, charLimit);
        let displayString = shortenedString;
        if (originalString.length > charLimit) {
            displayString += "...";
        }
        return (
            <Cell {...props}>
                <p>{displayString} {originalString.length > charLimit ? <button className="readMoreBtn" onClick={openCommentDetailFunc}>Read More</button> : null}</p>
            </Cell>
        );
    };
    // Action Cell 
    const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
        return (
            <Cell {...props}>
                <div className="editBtn">
                    <Button
                        onClick={() => {
                            handleEditClick()
                        }}
                        className='EditIcon me-2'
                    >
                        <EditIcon />
                    </Button>
                </div>
            </Cell>
        );
    };

    const handleDeleteClick = () => {
        props?.setDeletepopup(true)
    }
    const handleEditClick = () => {
        props?.setEditPopup(true)
    }

    //Fetch the row data for each
    const handleRowClick = (rowData) => {
        setRowData(rowData);

    };


    useEffect(() => {
        props?.setTableRowData(rowData)
    }, [rowData])


    //** Use Effect for changing the state in mobile devices */

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        { console.log(viewportWidth, "viewPort inner") }
        const handleResize = () => {

            if (viewportWidth < 1024) {
                setFixedState(false);
                console.log("condition true")

            } else {
                setFixedState(true);
                console.log("condition false")
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        switch (true) {
            case viewportWidth >= 1920:
                setTableHeight(650);
                break;
            case viewportWidth >= 1800:
                setTableHeight(650);
                break;
            case viewportWidth >= 1600:
                setTableHeight(750);
                break;
            case viewportWidth >= 1400:
                setTableHeight(450);
                break;
            case viewportWidth >= 1366:
                setTableHeight(350);
                break;
            case viewportWidth < 1366:
                setTableHeight(600);
                break;
            default:
                setTableHeight(460);
        }
    }, [])

    return (
        <>
            <Table
                data={getData()}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<div class="text-center">
                                    <div class="nodatafolder mb-2">
                                        <img src="${Assets.noDataFolder}" alt="No data found" />
                                    </div>
                                    <p>No Data Found</p>
                                    </div>`
                            }}
                        />
                    )
                }}
                height={tableHeight}
                headerHeight={72}
                hover={false}
                loading={loading}
                cellBordered={false}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                onRowClick={handleRowClick}
                wordWrap="break-word"
                rowHeight={100}
            >
                <Column width={150} fixed={fixedState ? true : false}>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey="weeklyText" />
                </Column>
                <Column width={250} >
                    <HeaderCell>Description</HeaderCell>
                    <DescriptionCell dataKey="description" />
                </Column>
                <Column width={250}>
                    <HeaderCell>Comments</HeaderCell>
                    <CommentsCell dataKey="comments" />
                </Column>
                <Column width={150}>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="date" />
                </Column>
                <Column width={150}>
                    <HeaderCell>Comment by</HeaderCell>
                    <Cell dataKey="commentby" />
                </Column>

                <Column width={150} align="left" >
                    <HeaderCell className="text-left">Action</HeaderCell>
                    <ActionCell dataKey="id" />
                </Column>
            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <ArrowLeftBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow" >
                        <DoubleArrowBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default WeeklyCleaningTable