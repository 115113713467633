import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Toggle } from 'rsuite';

const PushNotifications = () => {
    return (
        <>
            <h3 className="heading-small m-b-30">Push Notifications</h3>
            <Row>
                <Col sm={6}>
                    <div className="pushNotificationText d-flex align-items-center justify-content-between">
                        <p>Turn on Push Notifications</p>      <Toggle defaultChecked size="xxl,xl,lg,md,sm,xs" />
                    </div>
                </Col>
            </Row>


        </>
    )
}

export default PushNotifications