import React from "react"
import { Outlet } from "react-router-dom";
import Assets from "../../constants/image";
import { ToastContainer } from "react-toastify";

const AuthLayout = () => {
    return (
        <div className={"AuthLayout"}>
            <div className="authenticationLayout">
                <div className={"left"}>
                    <Outlet />
                </div>
                <div className={"right"}>
                    <div className="right-img">
                        <img className={"img-fluid img-cover"} src={Assets?.SignInImage} alt={"signIn Image"} title={""} />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AuthLayout