import React from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import moment from "moment";

const CustomRangeCalender = (props) => {
    const handleClose = () => { props?.setshowPopup(false) };
    const handleDateChange = (range) => {
        console.log(moment(range).format("YYYY-MM-DD"), moment(range).format("YYYY-MM-DD"));
    };

    const handleCloseCalender = () => {
        const inputElement = document.querySelector('.rs-picker-toggle-textbox');
        props?.setRangeValue(inputElement?.value);
        console.log("Select Date", inputElement?.value)
    }

    return (
        <>
            <Modal
                className='deleteModal customRangeCalender '
                show={true}
                size="md"
                onHide={handleClose}
                top
                animation={false}

            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small'>{props?.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <div className="notication-card-container employeeDetail">
                        <Row>
                            <Col xs={12}>
                                <div className="dateRangeBox--holder">
                                    <DateRangePicker
                                        ranges={[]}
                                        hoverRange={date => [subDays(date, 0), addDays(date, 0)]}
                                        onSelect={handleDateChange}
                                        defaultOpen={true}
                                        placement='bottom'
                                        format='dd/MM/yyyy'
                                        character=" ~ "
                                        appearance="subtle"
                                        placeholder="Select Range"
                                        onClose={handleCloseCalender}
                                    />
                                </div>

                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-end mt-4">
                                    <Button className="glowBtn" onClick={handleClose}>Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CustomRangeCalender