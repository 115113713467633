import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import TimerReportTable from '../../../../components/History/TimerReport/TimerReportTable';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Time Report",
        link: "/",
    }
];

const defaultData = [
    {
        reportName: "Daily DTW time 12-1",
        timeReport: "02:58",
    },
    {
        reportName: "Daily WinPickup 12-1",
        timeReport: "02:58",
    },
    {
        reportName: "Daily DTW time total",
        timeReport: "02:58",
    },
    {
        reportName: "Daily WinPickup total",
        timeReport: "02:58",
    },
    {
        reportName: "Daily DTW Cars total",
        timeReport: "02:58",
    },
    {
        reportName: "Sales Per Man Hour",
        timeReport: "02:58",
    },
    {
        reportName: "MTD DTW Cars 12-1",
        timeReport: "02:58",
    },
    {
        reportName: "MTD DTW time total",
        timeReport: "02:58",
    },
    {
        reportName: "MTD DTW Cars total",
        timeReport: "02:58",
    },
    {
        reportName: "MTD DTW Cars 12-1",
        timeReport: "02:58",
    },
    {
        reportName: "Daily DTW Cars total",
        timeReport: "02:58",
    }
]

const years = [
    { label: 2022, value: 2022 },
    { label: 2021, value: 2021 },
    { label: 2020, value: 2020 },
    { label: 2019, value: 2019 },
    { label: 2018, value: 2018 },
    { label: 2017, value: 2017 },
    { label: 2016, value: 2016 },
    { label: 2015, value: 2015 },
    { label: 2014, value: 2014 },
    { label: 2013, value: 2013 }
]

const TimerReport = () => {
    return (
        <>
            <FilterBar
                pageName={"Time Report"}
                firstDropdown={years}
                secondDropdown={false}
                defaultValueFirst={years[0]}
                btnText={"Sync"}
                breadCrumb={breadCrumb}
            />
            <div className="pageContainer">
                <TimerReportTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default TimerReport