import React, { useState } from 'react'
import FilterBar from '../../../components/FilterBar';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap'
import { VALIDATIONS_TEXT, maxBounds, } from '../../../constants/Constants';
import { EditBlueIcon, LineArrow } from '../../../components/ThemeSvgs';

import CustomRangeCalender from '../../../components/Popups/CustomRangeCalender';

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]



const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]

const DailyActivities = () => {
    const [editBtn, setEditBtn] = useState(false);
    const [nextBtn, setNextBtn] = useState(true)
    const [readOnlyState, setReadOnlyState] = useState(false)
    const [showPopup, setshowPopup] = useState(false);
    const [rangeValue, setRangeValue] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = data => {
        console.log(data);
        setEditBtn(true)
        setReadOnlyState(true)
        setNextBtn(false)
    }

    const handleEdit = () => {
        setReadOnlyState(!readOnlyState);
        setNextBtn(true)
    }

    const dayTime = [
        { value: '1', label: 'Today' },
        { value: '2', label: 'Yesterday' },
        { value: rangeValue, label: "Custom Range", custom: "custom" },
    ]

    console.log(errors)

    return (
        <>
            {showPopup === true && <CustomRangeCalender setRangeValue={setRangeValue} setshowPopup={setshowPopup} heading={"Select Custom Range"} />}
            <FilterBar
                pageName={"Goals & Daily Activities"}
                firstDropdown={false}
                defaultValueFirst={false}
                secondDropdown={dayTime}
                defaultValueSecond={dayTime[0]}
                btnText={false}
                breadCrumb={false}
                addPopupBtn={false}
                setAddPopup={false}
                includeSearchBar={false}
                setshowingPopup={setshowPopup}
                rangeValue={rangeValue}
            />
            <div className="pageContainer">
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                            <div className="dailyActivitiesWrapper mt-4 m-3">
                                {editBtn && <Button className="outlineBtn editBtn" onClick={handleEdit} ><EditBlueIcon /> Edit</Button>}
                                <div className="dailyActivitiesContainer">
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <div className="dailyActivitiesFormContainer ">
                                                <div className="dialyActivityBox mb-5">
                                                    <h4 className='mb-4 heading-small'>Pre-Opening RUMBLE</h4>
                                                    <Form.Group className="themeInputGroup mb-4" >
                                                        <Form.Label>Shift Goals *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("shiftGoal",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.SHIFT_GOAL_REQUIRED
                                                                    },
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />
                                                        {errors.shiftGoal && <Form.Text>{errors.shiftGoal.message}</Form.Text>}
                                                    </Form.Group>


                                                    <Form.Group className="themeInputGroup mb-4" controlId="topDetails">
                                                        <Form.Label>Tip Details *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("topDetails",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.TOP_DETAILS_REQUIRED
                                                                    },
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />

                                                        {errors.topDetails && <Form.Text>{errors.topDetails.message}</Form.Text>}
                                                    </Form.Group>

                                                    <Form.Group className="themeInputGroup mb-4" controlId="feedback">
                                                        <Form.Label>Feedback (T.O.P) *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("feedback",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.FEEDBACK_REQUIRED
                                                                    },
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />

                                                        {errors.feedback && <Form.Text>{errors.feedback.message}</Form.Text>}
                                                    </Form.Group>
                                                </div>
                                                <div className="dialyActivityBox mb-5">
                                                    <h4 className='mb-4 heading-small'>Shift Change RUMBLE</h4>
                                                    <Form.Group className="themeInputGroup mb-4" controlId="shiftGoalChange">
                                                        <Form.Label>Shift Goals *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("shiftGoalChange",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.SHIFT_GOAL_REQUIRED
                                                                    },
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />

                                                        {errors.shiftGoalChange && <Form.Text>{errors.shiftGoalChange.message}</Form.Text>}
                                                    </Form.Group>
                                                    <Form.Group className="themeInputGroup mb-4" controlId="topDetailsChange">
                                                        <Form.Label>Tip Details *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("topDetailsChange",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.TOP_DETAILS_REQUIRED
                                                                    },
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />

                                                        {errors.topDetailsChange && <Form.Text>{errors.topDetailsChange.message}</Form.Text>}
                                                    </Form.Group>
                                                    <Form.Group className="themeInputGroup mb-4" controlId="feedbackChange">
                                                        <Form.Label>Feedback (T.O.P) *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("feedbackChange",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.FEEDBACK_REQUIRED
                                                                    },
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />

                                                        {errors.feedbackChange && <Form.Text>{errors.feedbackChange.message}</Form.Text>}
                                                    </Form.Group>
                                                </div>
                                                <div className="dialyActivityBox mb-5">
                                                    <h4 className='mb-4 heading-small'>Recognitions</h4>
                                                    <Form.Group className="themeInputGroup mb-4" controlId="recognitions">
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            readOnly={readOnlyState}
                                                            {...register("recognitions",
                                                                {
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })
                                                            }
                                                        />

                                                        {errors.recognitions && <Form.Text>{errors.recognitions.message}</Form.Text>}
                                                    </Form.Group>
                                                </div>
                                                <div className="dialyActivityBox mb-5">
                                                    <h4 className='mb-4 heading-small'>Note</h4>
                                                    <Form.Group className="themeInputGroup mb-4" controlId="note">
                                                        <Form.Control
                                                            as="textarea"
                                                            readOnly={readOnlyState}
                                                            placeholder="Enter your text here"
                                                            rows={2}
                                                            {...register("note",
                                                                {
                                                                    maxLength: {
                                                                        value: maxBounds.TEXTAREA,
                                                                        message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                                    }
                                                                })}
                                                        />

                                                        {errors.note && <Form.Text>{errors.note.message}</Form.Text>}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Col>


                                    </Row>

                                </div>
                                <div className="activiyBottom">
                                    {nextBtn && <Button type={"submit"} className={"glowBtn btn-submit "}>
                                        Next
                                    </Button>}
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default DailyActivities