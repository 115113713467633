import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import AddStoreLinkPopup from '../../../../components/Rumbucks/AddRumbucksPopup';
import EditWeeklyCleaningPopup from "../../../../components/DailyActivities/WeeklyCleaning/EditWeeklyCleaningPopup"
import { defaultData, allStores, breadCrumb, monthProps, timeSpan } from "./data"
import WeeklyCleaningTable from '../../../../components/DailyActivities/WeeklyCleaning/WeeklyCleaningTable';
import DetailShowingPopup from '../../../../components/Popups/DetailShowingPopup';
import DetailCommentPopup from '../../../../components/Popups/DetailCommentPopup';

const WeeklyCleaning = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false);
    const [showPopup, setshowPopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [tableRowData, setTableRowData] = useState([])
    console.log(tableRowData, "tableRowData")
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> store link?"} />}
            {editPopup === true && <EditWeeklyCleaningPopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddStoreLinkPopup setAddPopup={setAddPopup} />}
            {showPopup === true && <DetailShowingPopup heading={"Dinning Room"} columnName={"Description:"} columnDetail={tableRowData?.description} setshowingPopup={setshowPopup} />}
            {showCommentPopup === true && <DetailCommentPopup heading={"Dinning Room"} columnName={"Comment:"} columnDetail={tableRowData?.description} setShowCommentPopup={setShowCommentPopup} />}
            <FilterBar
                pageName={"Weekly Cleaning"}
                firstDropdown={false}
                defaultValueFirst={false}
                secondDropdown={monthProps}
                defaultValueSecond={monthProps[2]}
                thirdDropdown={timeSpan}
                thirdDropdownPlaceHolder={"Time Span"}
                btnText={false}
                breadCrumb={breadCrumb}
                addPopupBtn={false}
                setAddPopup={false}
            />
            <div className="pageContainer">
                <WeeklyCleaningTable defaultData={defaultData} showCommentPopup={setShowCommentPopup} setshowPopup={setshowPopup} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} setTableRowData={setTableRowData} />
            </div>
        </>
    )
}

export default WeeklyCleaning