import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../constants/Constants";
import Select, {
    components,
} from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from 'rsuite';
import moment from 'moment';
import { isEmpty } from 'lodash';

const trainingList = [
    { value: 'training1', label: 'Management Training' },
    { value: 'training2', label: 'HR Training' },
    { value: 'training3', label: 'Management Training' }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const AddEmployeesPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState(false)
    const { control, register, handleSubmit, setValue, reset, formState: { errors }, watch } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setAddPopup(false);

    //On Save Btn function

    //On Submit function

    const onSubmit = (data) => {
        const startDate = moment(data.startDate).format("DD-MM-YYYY");
        const terminationDate = moment(data.terminationDate).format("DD-MM-YYYY");
        const birthDate = moment(data.birthDate).format("DD-MM-YYYY");

        console.log("Error Message", terminationDate, startDate, birthDate);
        if (terminationDate < birthDate || terminationDate < startDate) {
            setError(true)
        }


        else {


            console.log("Error Message", startDate, terminationDate, birthDate)
            setDisable(true);

            console.log("Add Employee Data", data);


            setTimeout(function () {
                setDisable(false);
                props?.setAddPopup(false)
                toast.success('Employee has been added', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }, 4000);
        }
    };

    return (
        <>

            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Add Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <div className="innerFormScroll">
                            <Row>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-3" controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.firstName ? "hasError" : ""}
                                            maxLength={maxBounds.NAME}
                                            {...register("firstName", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                },
                                                pattern: {
                                                    value: Patterns.Name,
                                                    message: VALIDATIONS_TEXT.NAME_PATTERN
                                                },
                                                maxLength: {
                                                    value: maxBounds.NAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                }
                                            })}
                                        />
                                        {errors.firstName && <Form.Text>{errors.firstName.message}</Form.Text>}
                                    </Form.Group>

                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-3" controlId="lastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.lastName ? "hasError" : ""}
                                            maxLength={maxBounds.NAME}
                                            {...register("lastName", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                },
                                                pattern: {
                                                    value: Patterns.Name,
                                                    message: VALIDATIONS_TEXT.NAME_PATTERN
                                                },
                                                maxLength: {
                                                    value: maxBounds.NAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                }
                                            })}
                                        />
                                        {errors.lastName && <Form.Text>{errors.lastName.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="startDate">
                                        <Form.Label>Start Date</Form.Label>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            className={errors.startDate ? "hasError" : ""}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    className='form-control'
                                                    placeholder="Add Start Date"
                                                    format="dd/MM/yyyy"
                                                    block
                                                    oneTap
                                                    placement="bottomStart" />
                                            )}
                                        />
                                        {errors.startDate && <Form.Text>{errors.startDate.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                        <Form.Label>Termination Date</Form.Label>
                                        <Controller
                                            control={control}
                                            name="terminationDate"
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    className='form-control'
                                                    placeholder="Add Term Date"
                                                    format="dd/MM/yyyy"
                                                    block
                                                    oneTap
                                                    placement="bottomStart" />
                                            )}
                                        />

                                        {error && <Form.Text>Termination Date Can't be less than DOB and Start Date</Form.Text>}
                                        {/* {!error && isEmpty(control.getValues('terminationDate')) && <Form.Text>Termination Date is required</Form.Text>} */}
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                        <Form.Label>Birth Date</Form.Label>
                                        <Controller
                                            control={control}
                                            name="birthDate"
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    className='form-control'
                                                    placeholder="Add Birth Date"
                                                    format="dd/MM/yyyy"
                                                    block
                                                    oneTap
                                                    placement="bottomStart" />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="empolyeeEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="youremail@gmail.com"
                                            className={errors.empolyeeEmail ? "hasError" : ""}
                                            maxLength={maxBounds.EMAIL}
                                            {...register("employeeEmail", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                },
                                                pattern: {
                                                    value: Patterns.Email,
                                                    message: VALIDATIONS_TEXT.EMAIL_PATTERN
                                                },
                                                maxLength: {
                                                    value: maxBounds.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                }
                                            })}
                                        />
                                        {errors.empolyeeEmail && <Form.Text>{errors.signInEmail.message}</Form.Text>}
                                    </Form.Group>
                                    {/* <Form.Group className="themeInputGroup mb-3" controlId="signInEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="youremail@gmail.com"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.signInEmail ? "hasError" : ""}
                                            maxLength={maxBounds.EMAIL}
                                            {...register("signInEmail", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                },
                                                pattern: {
                                                    value: Patterns.Email,
                                                    message: VALIDATIONS_TEXT.EMAIL_PATTERN
                                                },
                                                maxLength: {
                                                    value: maxBounds.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                }
                                            })}
                                        />
                                        {errors.signInEmail && <Form.Text>{errors.signInEmail.message}</Form.Text>}
                                    </Form.Group> */}
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                        <Form.Label>Training</Form.Label>
                                        <Select
                                            className="rumSelect normal w-100"
                                            classNamePrefix="rumSelect"
                                            options={trainingList}
                                            placeholder="Select Training"
                                            name="reactSelectName"
                                            // menuIsOpen={true}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={colourStyles}
                                            {...register("training")}
                                        />
                                    </Form.Group>
                                </Col>


                            </Row>
                        </div>
                        <div className="d-flex justify-content-end pt-3">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Add</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddEmployeesPopup