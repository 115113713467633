import React, { useState } from 'react';
import TenureTable from '../../../../components/employees/Tenure/TenureTable';
import FilterBar from '../../../../components/FilterBar';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Tenure",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 2,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 3,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 4,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 5,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 6,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 7,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 8,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 9,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 10,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 11,
        name: "Jane Copper",
        tenure: "2 Years",
    },
    {
        id: 12,
        name: "Jane Copper",
        tenure: "2 Years",
    }
]

const Tenure = () => {
    return (
        <>

            <FilterBar
                pageName={"Tenure"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={false}
                breadCrumb={breadCrumb}
                ArrowBtn={false}
                addPopupBtn={false}
            />
            <div className="pageContainer">
                <TenureTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default Tenure