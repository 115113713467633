import React, { useRef, useState } from "react"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { AdminIcon, ArrowLeftGrey, HidePasswordIcon, LineArrow, StoreIcon, ViewPasswordIcon } from "../../components/ThemeSvgs";
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../constants/Constants";
import OtpInput from "react-otp-input";
import StepThreeVerification from "../auth/StepThreeVerification";

const Register = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(1);
    const [stepErrors, setStepErrors] = useState(false);
    const [nextBtn, setNextBtn] = useState(true);
    const [otpCode, setOtpCode] = useState("");
    /*
    * stores selected account step
    * */
    const [selectedAccountType, setSelectedAccountType] = useState(null);

    /*
    * Verification code specific states
    * */
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disable, setDisable] = useState(false);

    /*
    * submit function for - step 1
    * and redirecting to related component of admin or store
    * */
    const onSubmitStepOne = (data) => {
        console.log("step 1: ", data);

        if (data.accountOptions === "admin") {
            setSelectedAccountType("admin");
            setActiveStep(2);
        }
        if (data.accountOptions === "store") {
            setSelectedAccountType("store");
            setActiveStep(2);
        }
    }

    /*
    * submit function for admin signup - step 2
    * */
    const onSubmitStepTwo = (data) => {
        console.log("step 2: ", data);
        setActiveStep(3);
    }
    /*
    * submit function for store signup - step 2
    * */
    const onSubmitStepTwoStore = (data) => {
        console.log("step 2: ", data);
        setActiveStep(3);
    }



    /*
    * first step component to select admin or store
    * */
    const StepOne = (props) => {
        const { register, handleSubmit, watch, formState: { errors } } = useForm({
            mode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
        });

        return (
            <div>
                <h3>Choose Account Type</h3>
                <Form onSubmit={handleSubmit(onSubmitStepOne)}>

                    <ul className={"list-inline mt-5 mx-auto text-center"}>
                        {/* <li className={"list-inline-item"}>
                            <div className={"radioGroup cursor-pointer"}>
                                <input
                                    className={"form-check d-none"}
                                    type={"radio"}
                                    id={"accountOptions-admin"}
                                    defaultChecked={true}
                                    value={"admin"}
                                    {...register("accountOptions", {
                                        onChange: (e) => {
                                            setNextBtn(true)
                                            // console.log(e.target.value);
                                        }
                                    })}
                                />
                                <Form.Label htmlFor={"accountOptions-admin"}>
                                    <AdminIcon />
                                    <span>Admin</span>
                                </Form.Label>
                            </div>
                        </li> */}
                        <li className={"list-inline-item"}>
                            <div className={"radioGroup cursor-pointer"}>
                                <input
                                    className={"form-check d-none"}
                                    type={"radio"}
                                    id={"accountOptions-store"}
                                    value={"store"}
                                    defaultChecked={true}
                                    {...register("accountOptions", {
                                        onChange: (e) => {
                                            setNextBtn(true)
                                            // console.log(e.target.value);
                                        }
                                    })}
                                />
                                <Form.Label htmlFor={"accountOptions-store"}>
                                    <StoreIcon />
                                    <span>Store</span>
                                </Form.Label>
                            </div>
                        </li>
                        {errors.accountOptions && <li className={"list-inline-item"}><Form.Text>{errors.accountOptions.message}</Form.Text></li>}
                    </ul>
                    <div className={"bottomBar"}>
                        <Row className={"align-items-center"}>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <p className={"mb-0"}>Have an account? <Link to={"/"} className={"link-inline"}>Login</Link></p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className={"text-end"}>
                                <ul className={"list-inline"}>

                                    <li className={"list-inline-item"}>
                                        <Button type={"submit"} disabled={!nextBtn ? true : false} className={"btn-submit"}>
                                            <span>Next Step</span> <LineArrow />
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        )
    }
    /*
    * Admin Signup component
    * */
    const StepTwoAdmin = (props) => {
        const [passwordShow, setPasswordShow] = useState(false);
        const [cPasswordShow, setCPasswordShow] = useState(false);

        const { register, handleSubmit, watch, formState: { errors } } = useForm({
            mode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
        });

        const passwordWatch = useRef({});
        passwordWatch.current = watch("signUpPassword", "");

        return (
            <div>
                <h3>Account Setup</h3>
                <Form onSubmit={handleSubmit(onSubmitStepTwo)}>
                    <div className={"paddingContainer"}>
                        <Form.Group className="themeInputGroup mb-3" controlId="signUpName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="your name"
                                autoComplete="off"
                                role={"presentation"}
                                className={errors.signUpName ? "hasError" : ""}
                                maxLength={maxBounds.NAME}
                                {...register("signUpName", {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.NAME_REQUIRED
                                    },
                                    pattern: {
                                        value: Patterns.Name,
                                        message: VALIDATIONS_TEXT.NAME_PATTERN
                                    },
                                    maxLength: {
                                        value: maxBounds.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    }
                                })}
                            />
                            {errors.signUpName && <Form.Text>{errors.signUpName.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="themeInputGroup mb-3" controlId="signUpEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="youremail@gmail.com"
                                autoComplete="off"
                                role={"presentation"}
                                className={errors.signInEmail ? "hasError" : ""}
                                maxLength={maxBounds.EMAIL}
                                {...register("signUpEmail", {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: Patterns.Email,
                                        message: VALIDATIONS_TEXT.EMAIL_PATTERN
                                    },
                                    maxLength: {
                                        value: maxBounds.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    }
                                })}
                            />
                            {errors.signUpEmail && <Form.Text>{errors.signUpEmail.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="themeInputGroup passwordInput mb-3" controlId="signUpPassword">
                            <Form.Label>New Password</Form.Label>
                            <InputGroup className={errors.signUpPassword ? "hasError" : ""}>
                                <Form.Control
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="••••••••"
                                    aria-describedby="passToggle"
                                    className={"passwordControl"}
                                    minLength={minBounds.PASSWORD}
                                    maxLength={maxBounds.PASSWORD}
                                    autoComplete="off"
                                    role={"presentation"}
                                    {...register("signUpPassword", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                        pattern: {
                                            value: Patterns.Password,
                                            message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                        },
                                        minLength: {
                                            value: minBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: maxBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        }
                                    })}
                                />
                                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setPasswordShow(!passwordShow)}>
                                    {
                                        passwordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                    }
                                </Button>
                            </InputGroup>

                            {errors.signUpPassword && <Form.Text>{errors.signUpPassword.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="themeInputGroup passwordInput mb-3" controlId="signUpCPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup className={errors.signUpCPassword ? "hasError" : ""}>
                                <Form.Control
                                    type={cPasswordShow ? "text" : "password"}
                                    placeholder="••••••••"
                                    aria-describedby="passToggle"
                                    className={"passwordControl"}
                                    minLength={minBounds.PASSWORD}
                                    maxLength={maxBounds.PASSWORD}
                                    autoComplete="off"
                                    role={"presentation"}
                                    {...register("signUpCPassword", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                        pattern: {
                                            value: Patterns.Password,
                                            message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                        },
                                        validate: value => value === passwordWatch.current || "The passwords do not match",
                                        minLength: {
                                            value: minBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: maxBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        }
                                    })}
                                />
                                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setCPasswordShow(!cPasswordShow)}>
                                    {
                                        cPasswordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                    }
                                </Button>
                            </InputGroup>

                            {errors.signUpCPassword && <Form.Text>{errors.signUpCPassword.message}</Form.Text>}
                        </Form.Group>
                    </div>

                    <div className={"bottomBar"}>
                        <Row className={"align-items-center"}>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <p className={"mb-0"}>Have an account? <Link to={"/"} className={"link-inline"}>Login</Link></p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className={"text-end"}>
                                <ul className={"list-inline"}>
                                    <li className={"list-inline-item"}>
                                        <Button type={"submit"} disabled={!nextBtn ? true : false} className={"btn-submit"}>
                                            <span>Continue</span> <LineArrow />
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        )
    }

    /*
    * store Signup component
    * */
    const StepTwoStore = (props) => {
        const [passwordShow, setPasswordShow] = useState(false);
        const [cPasswordShow, setCPasswordShow] = useState(false);
        const { register, handleSubmit, watch, formState: { errors } } = useForm({
            mode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
        });

        const passwordWatch = useRef({});
        passwordWatch.current = watch("signUpStorePassword", "");

        return (
            <div>
                <h3>Store Setup</h3>
                <Form onSubmit={handleSubmit(onSubmitStepTwoStore)}>

                    <div className={"paddingContainer"}>
                        <Form.Group className="themeInputGroup mb-3" controlId="signUpStoreName">
                            <Form.Label>Store Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Store Name"
                                autoComplete="off"
                                role={"presentation"}
                                className={errors.signUpStoreName ? "hasError" : ""}
                                maxLength={maxBounds.NAME}
                                {...register("signUpStoreName", {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.NAME_REQUIRED
                                    },
                                    pattern: {
                                        value: Patterns.Name,
                                        message: VALIDATIONS_TEXT.NAME_PATTERN
                                    },
                                    maxLength: {
                                        value: maxBounds.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    }
                                })}
                            />
                            {errors.signUpStoreName && <Form.Text>{errors.signUpStoreName.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="themeInputGroup mb-3" controlId="signUpStoreEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="youremail@gmail.com"
                                autoComplete="off"
                                role={"presentation"}
                                className={errors.signUpStoreEmail ? "hasError" : ""}
                                maxLength={maxBounds.EMAIL}
                                {...register("signUpStoreEmail", {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: Patterns.Email,
                                        message: VALIDATIONS_TEXT.EMAIL_PATTERN
                                    },
                                    maxLength: {
                                        value: maxBounds.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    }
                                })}
                            />
                            {errors.signUpStoreEmail && <Form.Text>{errors.signUpStoreEmail.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="themeInputGroup passwordInput mb-3" controlId="signUpStorePassword">
                            <Form.Label>New Password</Form.Label>
                            <InputGroup className={errors.signUpStorePassword ? "hasError" : ""}>
                                <Form.Control
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="••••••••"
                                    aria-describedby="passToggle"
                                    className={"passwordControl"}
                                    minLength={minBounds.PASSWORD}
                                    maxLength={maxBounds.PASSWORD}
                                    autoComplete="off"
                                    role={"presentation"}
                                    {...register("signUpStorePassword", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                        pattern: {
                                            value: Patterns.Password,
                                            message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                        },
                                        minLength: {
                                            value: minBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: maxBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        }
                                    })}
                                />
                                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setPasswordShow(!passwordShow)}>
                                    {
                                        passwordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                    }
                                </Button>
                            </InputGroup>

                            {errors.signUpStorePassword && <Form.Text>{errors.signUpStorePassword.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group className="themeInputGroup passwordInput mb-3" controlId="signUpStoreCPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup className={errors.signUpStoreCPassword ? "hasError" : ""}>
                                <Form.Control
                                    type={cPasswordShow ? "text" : "password"}
                                    placeholder="••••••••"
                                    aria-describedby="passToggle"
                                    className={"passwordControl"}
                                    minLength={minBounds.PASSWORD}
                                    maxLength={maxBounds.PASSWORD}
                                    autoComplete="off"
                                    role={"presentation"}
                                    {...register("signUpStoreCPassword", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                        pattern: {
                                            value: Patterns.Password,
                                            message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                        },
                                        validate: value => value === passwordWatch.current || "The passwords do not match",
                                        minLength: {
                                            value: minBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: maxBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        }
                                    })}
                                />
                                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setCPasswordShow(!cPasswordShow)}>
                                    {
                                        cPasswordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                    }
                                </Button>
                            </InputGroup>

                            {errors.signUpStoreCPassword && <Form.Text>{errors.signUpStoreCPassword.message}</Form.Text>}
                        </Form.Group>
                    </div>
                    <div className={"bottomBar"}>
                        <Row className={"align-items-center"}>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <p className={"mb-0"}>Have an account? <Link to={"/"} className={"link-inline"}>Login</Link></p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className={"text-end"}>
                                <ul className={"list-inline"}>
                                    <li className={"list-inline-item"}>
                                        <Button type={"submit"} onClick={() => { setActiveStep(1) }} className={"btn-submit prev-btn"}>
                                            <ArrowLeftGrey /> <span>Previous</span>
                                        </Button>
                                    </li>
                                    <li className={"list-inline-item"}>
                                        <Button type={"submit"} disabled={!nextBtn ? true : false} className={"btn-submit"}>
                                            <span>Next Step</span> <LineArrow />
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        )
    }

    /*
    * Verification component for admin and store both
    * */
    <StepThreeVerification />

    return (
        <div className={"RegisterLayout"}>
            <div className={"RegisterLayoutContainer"}>
                <Container fluid className="h-100">
                    <Row className={"h-100"}>
                        <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={4}>
                            <div className={"stepsContainer bg-blue h-100"}>
                                <div className={" h-100 stepsSettingLeft"}>
                                    <h1>
                                        Get started
                                    </h1>
                                    <ul className={"list-unstyled list-steps"}>
                                        <li className={activeStep === 1 || activeStep === 2 || activeStep === 3 ? "active" : ""}>
                                            <NavLink >Select account type</NavLink>
                                        </li>
                                        <li className={activeStep === 2 || activeStep === 3 ? "active" : ""}>
                                            <NavLink >Setup account</NavLink>
                                        </li>
                                        <li className={activeStep === 3 ? "active" : ""}>
                                            <NavLink >Account verification</NavLink>
                                        </li>
                                    </ul>

                                </div>

                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={8} xl={9} xxl={8}>
                            <div className={"stepsContainer bg-white h-100"}>
                                <div className={"stepsSettingRight"}>
                                    <span className={"stepCounters"}>Step {activeStep}/3</span>
                                    {/*
                                    * active step 1
                                    */}
                                    {
                                        activeStep === 1 && <StepOne />

                                    }
                                    {/*
                                    * active step 2 and admin
                                    */}
                                    {
                                        activeStep === 2 && selectedAccountType === "admin" ? <StepTwoAdmin /> : ''
                                    }
                                    {/*
                                    * active step 2 and store
                                    */}
                                    {
                                        activeStep === 2 && selectedAccountType === "store" ? <StepTwoStore /> : ''
                                    }

                                    {/*
                                    * active step 3
                                    */}
                                    {
                                        activeStep === 3 && <StepThreeVerification activeStep={activeStep} setActiveStep={setActiveStep} />

                                    }
                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Register