import React, {useState, useRef} from "react"
import {CrossSquared, HidePasswordIcon, LineArrow, LogoSvg, ViewPasswordIcon} from "../../components/ThemeSvgs";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {maxBounds, minBounds, Patterns, VALIDATIONS_TEXT} from "../../constants/Constants";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [disable, setDisable] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [cPasswordShow, setCPasswordShow] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    const passwordWatch = useRef({});
    passwordWatch.current = watch("resetPassword", "");


    const onSubmit = (data) => {
        setDisable(true);
        console.log("Forgot Password data:", data);
        toast.success('Password Reset Successfully', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setTimeout(function () {
            //code goes here
            setDisable(false);
            /*
            * navigate after sending code to verification code screen
            * */
            navigate("/");
        }, 4000);

    };

    return(
        <div className={"subAuthContainer resetPassword"}>
            <div>
                <Link to={"/"} className={"close-square m-r-20"}>
                    <CrossSquared />
                </Link>
                <LogoSvg />
            </div>


            <h3 className={"heading-small m-t-50"}>Reset Password</h3>
            <p className={"m-b-30"}>Strong passwords include numbers, letters and punctuation marks</p>

            <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Group className="themeInputGroup passwordInput mb-3" controlId="resetPassword">
                            <Form.Label>New Password</Form.Label>
                            <InputGroup className={errors.resetPassword ? "hasError" : ""}>
                                <Form.Control
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="••••••••"
                                    aria-describedby="passToggle"
                                    className={"passwordControl"}
                                    minLength={minBounds.PASSWORD}
                                    maxLength={maxBounds.PASSWORD}
                                    autoComplete="off"
                                    role={"presentation"}
                                    {...register("resetPassword", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                        pattern: {
                                            value: Patterns.Password,
                                            message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                        },
                                        minLength: {
                                            value: minBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: maxBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        }
                                    })}
                                />
                                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={()=>setPasswordShow(!passwordShow)}>
                                    {
                                        passwordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                    }
                                </Button>
                            </InputGroup>

                            {errors.resetPassword && <Form.Text>{errors.resetPassword.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Group className="themeInputGroup passwordInput mb-3" controlId="resetCPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup className={errors.resetCPassword ? "hasError" : ""}>
                                <Form.Control
                                    type={cPasswordShow ? "text" : "password"}
                                    placeholder="••••••••"
                                    aria-describedby="passToggle"
                                    className={"passwordControl"}
                                    minLength={minBounds.PASSWORD}
                                    maxLength={maxBounds.PASSWORD}
                                    autoComplete="off"
                                    role={"presentation"}
                                    {...register("resetCPassword", {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                        pattern: {
                                            value: Patterns.Password,
                                            message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                        },
                                        validate: value => value === passwordWatch.current || "The passwords do not match",
                                        minLength: {
                                            value: minBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: maxBounds.PASSWORD,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        }
                                    })}
                                />
                                <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={()=>setCPasswordShow(!cPasswordShow)}>
                                    {
                                        cPasswordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                    }
                                </Button>
                            </InputGroup>

                            {errors.resetCPassword && <Form.Text>{errors.resetCPassword.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Button type={"submit"} className={"w-100 btn-submit m-t-50"} disabled={disable ? true : false}>
                            {
                                !disable ? <>Verify <LineArrow /></> : <div className="load black"></div>
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ResetPassword