import React, { useEffect, useState, useContext } from "react";
import Assets from "../../../constants/image";
import { Table, Pagination } from 'rsuite';
import { Form, Button } from "react-bootstrap";
import { FixedColumnContext, TableHeightContext } from "../../../App";
const { Column, HeaderCell, Cell } = Table;


const demoData = [
    {
        id: 1,
        exterior: 'Building / Landscaping / Night Lights',
        type2: true

    },
    {
        id: 2,
        exterior: 'Dumpster Area Clean / SecureGrilled Chicken',
        type: true

    },
    {
        id: 3,
        exterior: 'Drive Thru / Menu Board / Cust View Clean',
        type: true
    },
    {
        id: 4,
        exterior: 'Lot / Drive Thru / Sidewalks Clean',
        type: true

    },
    {
        id: 5,
        exterior: 'Trash Cans Cleanliness / Empty',
        typ2: false

    },
    {
        id: 6,
        exterior: 'Window / Doors Clean',
        type: true

    },
    {
        id: 7,
        exterior: 'Exterior Lighting Clean / Functional',
        type2: false
    }
];

export const Entries = [
    { value: 'entry1', label: 'Entry 1' },
    { value: 'entry2', label: 'Entry 2' },
    { value: 'entry3', label: 'Entry 3' }
]

const handleCheckboxChange = (event) => {
    if (event.target.checked) {
        // The checkbox is checked
    } else {
        // The checkbox is not checked
    }
}
const ExteriorPrideTable = (props) => {

    const [data, setData] = useState(demoData);
    const [loading, setLoading] = useState(false);
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);

    //Table Action Cell 
    const CheckBoxCell = ({ rowData, dataKey, onClick, ...props }) => {

        return (
            <Cell {...props} style={{ padding: '6px' }}>
                {console.log(dataKey, rowData.type, "-=-===-=-=")}
                <div className="editBtn">
                    <Form.Check
                        inline
                        // label="1"
                        className="tableCheckbox"
                        type={"checkbox"}
                        defaultChecked={rowData.type}
                        onChange={(event) => handleCheckboxChange(event)}
                    />
                </div>
            </Cell>
        );
    };





    return (
        <>
            < Table
                data={demoData}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    `<div class="text-center">
                      <div class="nodatafolder mb-2">
                          <img src="${Assets.noDataFolder}" alt="No data found" />
                      </div>
                      <p>No Data Found</p>
                    </div>`
                            }}
                        />
                    )
                }}
                height={511}
                hover={false}
                loading={loading}
                cellBordered={false}
                headerHeight={80}
                className="driveTimeTable"
                virtualized
            >

                <Column fixed={fixedState ? true : false} width={360}>
                    <HeaderCell className='font-weight-bold'>Exterior</HeaderCell>
                    <Cell dataKey="exterior" />
                </Column>

                <Column flexGrow={1} >
                    <HeaderCell align="center" className='font-weight-bold'>Open</HeaderCell>
                    <CheckBoxCell dataKey="type" align="left" />
                </Column>
                <Column flexGrow={1}  >
                    <HeaderCell className='font-weight-bold'>2-4</HeaderCell>
                    <CheckBoxCell dataKey="type" />
                </Column>
                <Column flexGrow={1} >
                    <HeaderCell align="center" className='font-weight-bold'>5-6</HeaderCell>
                    <CheckBoxCell dataKey="type2" align="left" />
                </Column>
                <Column flexGrow={1}  >
                    <HeaderCell className='font-weight-bold'>Close</HeaderCell>
                    <CheckBoxCell dataKey="type" />
                </Column>

            </Table>

            <div className="mt-4 d-flex justify-content-end">
                <Button className="glowBtn">Save</Button>
            </div>
        </>
    )
}

export default ExteriorPrideTable