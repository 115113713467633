import React, { useState } from 'react'
import FilterBar from '../../../../components/FilterBar';
import { Tabs, Tab } from 'react-bootstrap';
import { breadCrumb, allStores, monthProps } from './data';
import AddCommentsPopup from '../../../../components/Popups/AddCommentsPopup';
import ExteriorPrideTable from '../../../../components/DailyActivities/Pride/ExteriorPrideTable';
import CustomerEnvirnmentTable from '../../../../components/DailyActivities/Pride/CustomerEnvirnmentTable';
import CustomerServiceTable from '../../../../components/DailyActivities/Pride/CustomerServiceTable';
import PrepServiceTable from '../../../../components/DailyActivities/Pride/PrepServiceTable';
import DriveThruAreaTable from '../../../../components/DailyActivities/Pride/DriveThruAreaTable';
import KitchenAreaTable from '../../../../components/DailyActivities/Pride/KitchenAreaTable';
import BackToAreaTable from '../../../../components/DailyActivities/Pride/BackToAreaTable';


const Pride = () => {
    const [showPopup, setshowPopup] = useState(false);
    return (
        <>
            {showPopup === true && <AddCommentsPopup setshowingPopup={setshowPopup} heading={"Add Comment"} />}
            <FilterBar
                pageName={"Pride"}
                setAddPopup={setshowPopup}
                addPopupBtn={true}
                firstDropdown={false}
                secondDropdown={monthProps}
                btnText={"Add Comment"}
                breadCrumb={breadCrumb}
                outlineBtn={true}
            />


            <div className="pageContainer tabs-Container pridePage bg-transparent shadow-none p-0">
                <Tabs defaultActiveKey="1" id="temperature-tabs" >
                    <Tab eventKey="1" title="Exterior" className='tabBoxContainer'>
                        <ExteriorPrideTable />
                    </Tab>
                    <Tab eventKey="2" title="Customer Environment" className='tabBoxContainer'>
                        <CustomerEnvirnmentTable />
                    </Tab>
                    <Tab eventKey="3" title="Customer Service Area" className='tabBoxContainer'>
                        <CustomerServiceTable />
                    </Tab>
                    <Tab eventKey="4" title="Prep" className='tabBoxContainer'>
                        <PrepServiceTable />
                    </Tab>
                    <Tab eventKey="5" title="Drive Thru Areas" className='tabBoxContainer'>
                        <DriveThruAreaTable />
                    </Tab>
                    <Tab eventKey="6" title="Kitchen Area" className='tabBoxContainer'>
                        <KitchenAreaTable />
                    </Tab>
                    <Tab eventKey="7" title="Back of House" className='tabBoxContainer'>
                        <BackToAreaTable />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Pride