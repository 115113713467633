import React, { useState, useContext } from "react";
import FilterBar from "../../../components/FilterBar";
import { Button, Pagination } from "react-bootstrap";
import { Table } from 'rsuite';
import Assets from "../../../constants/image";
import { ArrowLeftBold, DoubleArrowBold, EditIcon } from "../../../components/ThemeSvgs";
import { defaultData, storeData, monthProps } from "./dashboardData";
import { FixedColumnContext, TableHeightContext } from "../../../App";

const { Column, HeaderCell, Cell } = Table;

//** Editable Cell Functions */
const timeRegExp = /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](am|pm))$|^\d+$/i;

const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === 'EDIT';
    const [error, setError] = useState(false);
    const [inputValue, setInputValue] = useState(rowData[dataKey]);
    return (
        <Cell {...props} className={editing ? 'table-content-editing' : ''}>

            {editing ? (
                <input
                    className={`rs-input ${error ? 'error' : ''}`}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    onClick={() => {
                        if (inputValue === 'N/A') {
                            setInputValue('');
                        }
                    }}
                    onBlur={() => {
                        if (timeRegExp.test(inputValue)) {
                            setError(false);
                            onChange && onChange(rowData.id, dataKey, inputValue);
                        } else {
                            setError(true);
                            alert("Invalid Input. Please enter a valid value");
                        }
                    }}
                />

            ) : (
                <span className="table-content-edit-span">{rowData[dataKey]}</span>
            )}
        </Cell>
    );
};



const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    const [error, setError] = useState(false);
    return (
        <Cell {...props} style={{ padding: '6px' }}>
            <div className="editBtn">
                <Button
                    appearance="link"
                    onClick={() => {
                        if (rowData.status === 'EDIT') {
                            if (timeRegExp.test(rowData[dataKey])) {
                                onClick(rowData.id);
                                setError(false);
                            } else {
                                setError(true);
                                alert("Invalid Input. Please enter a valid value");
                            }
                        } else {
                            onClick(rowData.id);
                        }
                    }}
                >
                    {rowData.status === "EDIT" ? 'Save' : <EditIcon />}
                </Button>
            </div>
        </Cell>
    );
};





const Dashboard = () => {
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);
    const [data, setData] = useState(defaultData);
    const [loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    // const [paginatedData, setPaginatedData] = useState(defaultData);
    const [searchTerm, setSearchTerm] = useState(''); // For Search Inside the table 

    //Sate for the select dropdown to populate data. 
    const [slectedStoreOption, setSlectedStoreOption] = useState();
    console.log("slectedStoreOption", slectedStoreOption)


    /** For Sorting */

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const sortedData = data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
            x = x.charCodeAt();
        }
        if (typeof y === 'string') {
            y = y.charCodeAt();
        }
        if (sortType === 'asc') {
            return x - y;
        } else {
            return y - x;
        }
    });


    const onchangeFilterHandle = (event) => {
        let checkValue = event.target.value
        setSearchTerm(event.target.value);
        if (checkValue === '') {
            console.log(true)
            setData(defaultData);
        } else {
            let filteredData = Array.isArray(data)
                ? data.filter((item) =>
                    typeof item.sale === 'string' &&
                    item.sale.toLowerCase().includes(searchTerm.toLowerCase())
                )
                : [];
            setData(filteredData);
        }


    }

    /** For Edit the Row */
    const handleEditChange = (id, key, value) => {
        const nextData = Object.assign([], data);
        nextData.find(item => item.id === id)[key] = value;
        setData(nextData);
    };
    const handleEditState = id => {
        const nextData = Object.assign([], data);
        console.log(nextData, "nextData");
        const activeItem = nextData.find(item => item.id === id);
        console.log(activeItem, "activeItem");
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };



    return (
        <>
            <FilterBar pageName={"Dashboard"}
                firstDropdown={false}
                defaultValueFirst={false}
                firstDropdownPlaceholder={"Select"}
                secondDropdown={monthProps}
                getSelectedStore={setSlectedStoreOption}
                defaultValueSecond={monthProps[8]}
                btnText={"Sync"}
                breadCrumb={false}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchFunc={onchangeFilterHandle}
            />
            <div className="pageContainer">
                <div className="tabuleWrapBox">
                    {/* Note For Dev: Please adjust the column and data set regarding to the selected value. */}
                    {slectedStoreOption?.value === "wallys" ?
                        <Table
                            data={storeData}
                            locale={{
                                emptyMessage: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `<div class="text-center">
                                      <div class="nodatafolder mb-2">
                                          <img src="${Assets.noDataFolder}" alt="No data found" />
                                      </div>
                                      <p>No Data Found</p>
                                      </div>`
                                        }}
                                    />
                                )
                            }}

                            height={tableHeight}
                            hover={false}
                            loading={loading}
                            cellBordered={false}
                            headerHeight={60}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            affixHorizontalScrollbar={true}
                            className="dashboardTable"
                        >
                            <Column align="left" width={190} fixed={fixedState ? true : false}>
                                <HeaderCell></HeaderCell>
                                <Cell dataKey="sale" />
                            </Column>

                            <Column sortable width={150}>
                                <HeaderCell>Wally’s</HeaderCell>
                                <EditableCell dataKey="wallys" onChange={handleEditChange} />
                            </Column>

                            <Column width={150}>
                                <HeaderCell>Total</HeaderCell>
                                <EditableCell dataKey="total" onChange={handleEditChange} />
                            </Column>

                            <Column align="center" width={140} fixed={fixedState ? "right" : false}>
                                <HeaderCell className="text-center">Action</HeaderCell>
                                <ActionCell dataKey="id" onClick={handleEditState} />
                            </Column>
                        </Table>
                        :
                        <Table
                            data={data}
                            locale={{
                                emptyMessage: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `<div class="text-center">
                                        <div class="nodatafolder mb-2">
                                            <img src="${Assets.noDataFolder}" alt="No data found" />
                                        </div>
                                        <p>No Data Found</p>
                                        </div>`
                                        }}
                                    />
                                )
                            }}

                            height={tableHeight}
                            hover={false}
                            loading={loading}
                            cellBordered={false}
                            headerHeight={60}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            affixHorizontalScrollbar={true}
                            className="dashboardTable"
                        >
                            <Column align="left" width={190} fixed={fixedState ? true : false}>
                                <HeaderCell></HeaderCell>
                                <Cell dataKey="sale" />
                            </Column>

                            <Column sortable width={150}>
                                <HeaderCell>Wally’s</HeaderCell>
                                <EditableCell dataKey="wallys" onChange={handleEditChange} />
                            </Column>

                            <Column sortable width={150}>
                                <HeaderCell>Burnet Rd</HeaderCell>
                                <EditableCell dataKey="BurnetRd" onChange={handleEditChange} />
                            </Column>

                            <Column sortable width={150}>
                                <HeaderCell>Manor Rd</HeaderCell>
                                <EditableCell dataKey="ManorRd" onChange={handleEditChange} />
                            </Column>

                            <Column sortable width={150}>
                                <HeaderCell>Lockhart</HeaderCell>
                                <EditableCell dataKey="Lockhart" onChange={handleEditChange} />
                            </Column>

                            <Column width={150}>
                                <HeaderCell>Burn</HeaderCell>
                                <EditableCell dataKey="Burn" onChange={handleEditChange} />
                            </Column>

                            <Column width={150}>
                                <HeaderCell>Total</HeaderCell>
                                <EditableCell dataKey="total" onChange={handleEditChange} />
                            </Column>

                            <Column align="center" width={140} fixed={fixedState ? "right" : false}>
                                <HeaderCell className="text-center">Action</HeaderCell>
                                <ActionCell dataKey="id" onClick={handleEditState} />
                            </Column>
                        </Table>

                    }

                    <div className="pagiantion-box d-flex justify-content-end">
                        <Pagination>
                            <Pagination.First>
                                <ArrowLeftBold />
                            </Pagination.First>
                            <Pagination.Prev className="doubleArrow rotate180" >
                                <DoubleArrowBold />
                            </Pagination.Prev>
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item active>{2}</Pagination.Item>
                            <Pagination.Ellipsis />
                            <Pagination.Item>{100}</Pagination.Item>
                            <Pagination.Next >
                                <DoubleArrowBold />
                            </Pagination.Next>
                            <Pagination.Last className="doubleArrow rotate180" >
                                <ArrowLeftBold />
                            </Pagination.Last>
                        </Pagination>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard