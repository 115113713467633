import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'rsuite';
import Assets from '../../../constants/image';
import { Button, Pagination } from "react-bootstrap";
import { ArrowLeftBold, ArrowRight, DoubleArrowBold, EditIcon } from "../../../components/ThemeSvgs";
import { FixedColumnContext, TableHeightContext } from "../../../App";

const AnniversaryTable = (props) => {
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);
    const [data, setData] = useState(props?.defaultData);
    const [rowData, setRowData] = useState(null);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const { Column, HeaderCell, Cell } = Table; // Importing components from Rsuite Table
    //Function to sort the data
    const getData = () => {
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    //top open the popup
    const handleOpenDetail = () => {
        props?.openPopup(true)
    }
    //Fetch the row data for each
    const handleRowClick = (rowData) => {
        setRowData(rowData);

    };


    useEffect(() => {
        { props?.setTableRowData(rowData) }
    }, [rowData])



    const EmployeeCell = ({ rowData, dataKey, onClick, test, ...props }) => {
        const employeesArray = rowData?.employees
        const countAfter4thIndex = employeesArray?.slice(3)?.length;
        return (
            <Cell {...props}>

                <div className="emplpoyeeNameCell">
                    {employeesArray?.slice(0, 3)?.map((v, i) => {
                        const isLast = i === employeesArray?.slice(0, 3)?.length - 1;
                        return (
                            <div key={i} className="emplpoyeeNameCell">
                                <p>{v?.name}{isLast && employeesArray?.length > 4 ? '...' : ','}</p>
                            </div>
                        )
                    })}
                    {
                        employeesArray?.length > 4 && (
                            <button className='employeeMoreBtn' onClick={handleOpenDetail}> {countAfter4thIndex} More</button>
                        )
                    }
                </div>
            </Cell>
        );
    };

    return (
        <>
            <Table
                data={getData()}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<div class="text-center">
                                    <div class="nodatafolder mb-2">
                                        <img src="${Assets.noDataFolder}" alt="No data found" />
                                    </div>
                                    <p>No Data Found</p>
                                    </div>`
                            }}
                        />
                    )
                }}
                onRowClick={handleRowClick}
                height={tableHeight}
                headerHeight={72}
                hover={false}
                loading={loading}
                cellBordered={false}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                virtualized
            >


                <Column width={180} sortable>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="date" />
                </Column>

                <Column width={180} sortable>
                    <HeaderCell>Employees</HeaderCell>
                    <EmployeeCell dataKey="employees" fetchData={props.setTableRowData} />
                </Column>
            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <ArrowLeftBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow" >
                        <DoubleArrowBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default AnniversaryTable