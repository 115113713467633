import React, { useEffect, useState, useContext } from "react";
import Assets from "../../constants/image";
import { Table } from 'rsuite';
import { Button, Pagination } from "react-bootstrap";
import { ArrowLeftBold, ArrowRight, DoubleArrowBold, EditIcon } from "../ThemeSvgs";
import { FixedColumnContext, TableHeightContext } from "../../App";

const { Column, HeaderCell, Cell } = Table;


const demoData = [
    {
        id: 1,
        date: '1/9/22',
        store: "650",
        ytd: "1234",
        percentage: "1.2%",
    },
    {
        id: 2,
        date: '5/12/22',
        store: "650",
        ytd: "1234",
        percentage: "3.2%",
    },
    {
        id: 3,
        date: '2/9/22',
        store: "650",
        ytd: "1234",
        percentage: "2.2%",
    },
    {
        id: 4,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "1.2%",
    },
    {
        id: 5,
        date: '15/1/22',
        store: "650",
        ytd: "1234",
        percentage: "3.2%",
    },
    {
        id: 6,
        date: '20/5/22',
        store: "650",
        ytd: "1234",
        percentage: "2.2%",
    },
    {
        id: 7,
        date: '12/6/22',
        store: "650",
        ytd: "1234",
        percentage: "1.2%",
    },
    {
        id: 8,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "3.2%",
    },
    {
        id: 9,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "2.2%",
    },
    {
        id: 10,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "1.2%",
    },
    {
        id: 11,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "3.2%",
    },
    {
        id: 12,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "2.2%",
    },
    {
        id: 13,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "1.2%",
    },
    {
        id: 14,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "3.2%",
    },
    {
        id: 15,
        date: '20/9/22',
        store: "650",
        ytd: "1234",
        percentage: "2.2%",
    },
];

//** Editable Cell Functions */
const timeRegExp =  /.*/;

const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === 'EDIT';
    const [error, setError] = useState(false);
    const [inputValue, setInputValue] = useState(rowData[dataKey]);
    return (
        <Cell {...props} className={editing ? `table-content-editing ${props.className}` : `${props.className}`}>
            {editing ? (
                <input
                    className={`rs-input ${error ? 'error' : ''}`}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    onBlur={() => {
                        if (timeRegExp.test(inputValue)) {
                            setError(false);
                            onChange && onChange(rowData.id, dataKey, inputValue);
                        } else {
                            setError(true);
                            alert("Invalid Input. Please enter a valid value");
                        }
                    }}
                />
            ) : (
                <span className="table-content-edit-span">{rowData[dataKey]}</span>
            )}
        </Cell>
    );
};
const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    const [error, setError] = useState(false);
    return (
        <Cell {...props} style={{ padding: '6px' }}>
            <div className="editBtn">
                <Button
                    appearance="link"
                    onClick={() => {
                        if (rowData.status === 'EDIT') {
                            if (timeRegExp.test(rowData[dataKey])) {
                                onClick(rowData.id);
                                setError(false);
                            } else {
                                setError(true);
                                alert("Invalid Input. Please enter a valid value");
                            }
                        } else {
                            onClick(rowData.id);
                        }
                    }}
                >
                    {rowData.status === "EDIT" ? 'Save' : <EditIcon />}
                </Button>
            </div>
        </Cell>
    );
};


const MixChocolateTable = (props) => {
    const [data, setData] = useState(demoData);
    const [loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);

    /** For Sorting */
    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const sortedData = data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
            x = x.charCodeAt();
        }
        if (typeof y === 'string') {
            y = y.charCodeAt();
        }
        if (sortType === 'asc') {
            return x - y;
        } else {
            return y - x;
        }
    });

    /** For Edit the Row */
    const handleEditChange = (id, key, value) => {
        const nextData = Object.assign([], data);
        nextData.find(item => item.id === id)[key] = value;
        setData(nextData);
    };
    const handleEditState = id => {
        const nextData = Object.assign([], data);
        console.log(nextData, "nextData");
        const activeItem = nextData.find(item => item.id === id);
        console.log(activeItem, "activeItem");
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };


    return (
        <>
            <Table
                data={demoData}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    `<div class="text-center">
                      <div class="nodatafolder mb-2">
                          <img src="${Assets.noDataFolder}" alt="No data found" />
                      </div>
                      <p>No Data Found</p>
                    </div>`
                            }}
                        />
                    )
                }}
                height={tableHeight}
                hover={false}
                loading={loading}
                cellBordered={false}
                headerHeight={72}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                className="reportMixTable"
            >
                <Column sortable fixed={fixedState ? true : false}>
                    <HeaderCell className="justify-content-center">Date</HeaderCell>
                    <Cell dataKey="date" />
                </Column>

                {/** ----------------------- Without Blue Background Starts ----------------------- */}

                <Column width={130} align="center">
                    <HeaderCell align="center">Store</HeaderCell>
                    <EditableCell dataKey="store" align="left" onChange={handleEditChange} />
                </Column>
                <Column width={130} align="center">
                    <HeaderCell align="center">YTD</HeaderCell>
                    <EditableCell dataKey="ytd" onChange={handleEditChange} />
                </Column>
                <Column width={130} align="center">
                    <HeaderCell align="center">%</HeaderCell>
                    <EditableCell dataKey="percentage" onChange={handleEditChange} />
                </Column>

                {/** ----------------------- Without Blue Background Ends ----------------------- */}
               

            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <ArrowLeftBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow" >
                        <DoubleArrowBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default MixChocolateTable