import React, { useState } from 'react';
import TerminationTable from '../../../../components/employees/Termination/TerminationTable';
import FilterBar from '../../../../components/FilterBar';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Termination",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 2,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 3,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 4,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 5,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 6,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 7,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 8,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 9,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 10,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 11,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    },
    {
        id: 12,
        name: "Jane Copper",
        terminationDate: "9/15/2014",
    }
]

const Termination = () => {
    return (
        <>

            <FilterBar
                pageName={"Termination"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={false}
                breadCrumb={breadCrumb}
                ArrowBtn={false}
                addPopupBtn={false}
            />
            <div className="pageContainer">
                <TerminationTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default Termination