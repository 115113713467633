import React, { useState } from "react";
import FilterBar from "../../../components/FilterBar";
import SalesTable from "../../../components/History/Sales/SalesTable";

const monthProps = [
  { value: 'january', label: 'January' },
  { value: 'february', label: 'February' },
  { value: 'march', label: 'March' },
  { value: 'april', label: 'April' },
  { value: 'may', label: 'May' },
  { value: 'june', label: 'June' },
  { value: 'july', label: 'July' },
  { value: 'august', label: 'August' },
  { value: 'september', label: 'September' },
  { value: 'october', label: 'October' },
  { value: 'november', label: 'November' },
  { value: 'december', label: 'December' }
]

const years = [
  { label: 2022, value: 2022 },
  { label: 2021, value: 2021 },
  { label: 2020, value: 2020 },
  { label: 2019, value: 2019 },
  { label: 2018, value: 2018 },
  { label: 2017, value: 2017 },
  { label: 2016, value: 2016 },
  { label: 2015, value: 2015 },
  { label: 2014, value: 2014 },
  { label: 2013, value: 2013 }
]

const breadCrumb = [
  {
    name: "History",
    link: "/history/sales",
  },
  {
    name: "Sales",
    link: "",
  }
];



const Sales = () => {

  return (
    <>
      <FilterBar
        pageName={"Sales"}
        firstDropdown={years}
        secondDropdown={false}
        defaultValueFirst={years[0]}
        btnText={"Sync"}
        breadCrumb={breadCrumb}
      />
      <div className="pageContainer">
        <SalesTable />
      </div>
    </>
  )
}

export default Sales