import React, { useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { AdminIcon, ArrowLeftGrey, HidePasswordIcon, LineArrow, StoreIcon, ViewPasswordIcon } from "../../components/ThemeSvgs";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../constants/Constants";

const StepThreeVerification = (props) => {


    console.log("Step three Prop", props)



    const navigate = useNavigate();
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disable, setDisable] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });
    /*
   * handle otp change
   * */
    const handleOtpChange = (otp) => {
        setOtpCode(otp);
    }
    /*
    * submit function for store signup - step 2
    * */
    const onSubmitVerification = (data) => {
        console.log("step 3: ", data);
        setDisable(true);
        console.log("Verification Code:", otpCode);

        if (otpCode.length === 0) {
            setError(true);
            setDisable(false);
            setErrorMessage("Please Enter OTP code");
        } else if (otpCode.length > 0 && otpCode.length < 4) {
            setError(true);
            setDisable(false);
            setErrorMessage("OTP code is incomplete");
        } else {
            /*temporary code*/
            if (otpCode == 2222) {
                setError(false);
                setTimeout(function () {
                    //code goes here
                    setDisable(false);
                    navigate('/success');

                }, 3000); //Time before execution

            }
            else {
                setTimeout(function () {
                    //code goes here
                    setDisable(false);

                    toast.error('Invalid OTP Code', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }, 3000); //Time before execution
            }
        }
    }

    /** Change Step Function */
    const ChangeStep = () => {
        console.log("I am run")
    }

    /*
    * Resend verification code - step verification code
    * */
    const resendCode = () => {
        setTimeout(function () {
            //code goes here

            toast.success('Code Has Been Sent To Email', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 3000); //Time before execution
    }


    return (
        <div>
            <h3>Account Verification</h3>
            <p className={"text-center"}>Enter the verification code sent on your email<br /> address.</p>
            <Form onSubmit={handleSubmit(onSubmitVerification)} autoComplete={"off"}>
                <div className={"paddingContainer"}>
                    <Form.Group className="themeInputGroup OtpInput-container mb-3" controlId="signInEmail">
                        <Form.Label>Code from SMS</Form.Label>
                        <OtpInput
                            value={otpCode}
                            onChange={(otp) => handleOtpChange(otp)}
                            numInputs={4}
                            isInputNum={true}
                            placeholder={"----"}
                            className={"OtpInput"}
                            hasErrored={true}
                            focusStyle="focus"
                            autoFocus={true}
                        />
                        {error && <Form.Text>{errorMessage}</Form.Text>}
                    </Form.Group>
                    <p className={"text-center m-t-20"}>Haven’t received the code? <span className={"link-inline"} onClick={() => resendCode()}><b>Resend</b></span></p>
                </div>
                <div className={"bottomBar"}>
                    <Row className={"align-items-center"}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <p className={"mb-0"}>Have an account? <Link to={"/"} className={"link-inline"}>Login</Link></p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className={"text-end"}>
                            <ul className={"list-inline"}>
                                <li className={"list-inline-item"}>
                                    <div onClick={() => { props?.setActiveStep(2) }} className={"btn-submit prev-btn"}>
                                        <ArrowLeftGrey /> <span>Previous</span>
                                    </div>
                                </li>
                                <li className={"list-inline-item"}>
                                    <Button type={"submit"} className={"w-100 btn-submit"} disabled={disable ? true : false}>
                                        {
                                            !disable ? <>Submit <LineArrow /></> : <div className="load black"></div>
                                        }
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}



export default StepThreeVerification