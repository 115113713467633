import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../../constants/Constants";
import { DatePicker } from 'rsuite';
import { ToastContainer, toast } from "react-toastify";

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const EditWeeklyCleaningPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setEditPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);


        setTimeout(function () {
            setDisable(false);
            props?.setEditPopup(false)
            toast('Your Store Link Updated', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 4000);
    }


    useEffect(() => {
        reset({
            name: 'Adamson Byron',
            discription: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
            comment: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
            date: '24/06/22',
            commentby: 'Jane Copper',
        })
    }, [])
    return (
        <>

            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Edit Dinnig Room</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <div className="innerFormScroll">
                            <Row>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="linkUrl">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            {...register("discription")}
                                        />
                                        {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                        <Form.Label>Comment</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            {...register("comment")}
                                        />
                                        {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="linkUrl">
                                        <Form.Label>Date</Form.Label>
                                        <DatePicker defaultValue={new Date('2018-02-26')} className='form-control' format="dd/MM/yyyy" block oneTap placement="bottomStart" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="linkUrl">
                                        <Form.Label>Comment By</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Comment By"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.linkUrl ? "hasError" : ""}
                                            maxLength={maxBounds.NAME}
                                            {...register("commentby")}
                                        />
                                        {errors.link && <Form.Text>{errors.link.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                            </Row>
                        </div>
                        <div className="d-flex justify-content-end pt-3">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditWeeklyCleaningPopup