import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { maxBounds, VALIDATIONS_TEXT } from '../../constants/Constants';


const AddCommentsPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setshowingPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);


        setTimeout(function () {
            setDisable(false);
            props?.setshowingPopup(false)
            toast.success('Comment has been added', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 4000);

    }

    return (
        <>

            <Modal
                className='deleteModal detailModal '
                show={true}
                size="md"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Add Training</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup  mb-3" controlId="textArea">
                                    <Form.Control as="textarea" rows={3}
                                        {...register("comments",
                                            {
                                                maxLength: {
                                                    value: maxBounds.TEXTAREA,
                                                    message: VALIDATIONS_TEXT.TEXTAREA_MAX
                                                }
                                            })
                                        } />
                                </Form.Group>
                                {errors.comments && <Form.Text>{errors.comments.message}</Form.Text>}
                            </Col>

                        </Row>
                        <div className="d-flex justify-content-end pt-3">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCommentsPopup